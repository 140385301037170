import { createRouter, createWebHistory } from 'vue-router';
// import SubmitPrompt from './views/SubmitPrompt.vue';
import GenVideo from './views/GenVideoWithServer.vue';
import InpaintPage from './views/InpaintWithServer.vue';
import BigBoobPage from './views/BigBoobWithServer.vue';
import HairColored from './views/HairColoredWithServer.vue';
import GenImage from './views/GenImageWithServer.vue';
import GenSD3Image from './views/GenSD3ImageWithServer.vue';
import CustomCanvas from './views/CustomCanvas.vue';
import ImgStyleTransfor from './views/ImgStyleTransforWithServer.vue';  
import ImgEnhancer from './views/ImgEnhancerWithServer.vue';

let routes = [
    { path: '/video', name: 'VideoGen', component: GenVideo },
    { path: '/inpaint', name: 'Inpaint', component: InpaintPage },
    { path: '/bigboob', name: 'BigBoob', component: BigBoobPage },
    { path: '/hair', name: 'HairColored', component: HairColored },
    { path: '/aiimg', name: 'GenImage', component: GenImage },
    { path: '/sd3', name: 'GenSD3Image', component: GenSD3Image },
    { path: '/bg', name: 'GenScreenBackground', component: CustomCanvas },
    { path: '/style', name: 'ImgStyleTransfor', component: ImgStyleTransfor },
    { path: '/enhancer', name: 'ImgEnhancer', component: ImgEnhancer },
    { path: '/:pathMatch(.*)*', redirect: '/bigboob' }
];

if (process.env.VUE_APP_DEFAULT_PAGE != undefined) {
    routes.push({ path: '/', redirect: process.env.VUE_APP_DEFAULT_PAGE });
} else {
    routes.push({ path: '/', redirect: '/bigboob' });
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
