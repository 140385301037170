<template>
  <div class="container">
    <div class="dropdown dropdown-hover">
      <div tabindex="0" role="button" class="btn m-1">
        <span class="color-circle" :style="{ backgroundColor: selectedStyle.hex }">
        </span>
        {{ selectedStyle.name }}
      </div>
      <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
        <!-- 使用v-for动态生成发色选择项 -->
        <li v-for="(style_item, index) in styles" :key="index" class="menu-item">
          <a class="select-option" :data-value="style_item.value" @click="selectStyle(style_item.value)">
            <span class="color-circle" :style="{ backgroundColor: style_item.hex, borderColor: 'white' }"></span>
            {{ style_item.name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
  <ImageProcessWithServer class="hair-colored" 
    functionName="style"
    :params="{ style: selectedStyle.value }"
    :defaultImages="defaultImagePaths">
  </ImageProcessWithServer>
</template>

<script>
import { ref } from 'vue';

import ImageProcessWithServer from '../components/ImageProcessWithServer.vue';

export default {
  components: {
    ImageProcessWithServer,
  },
  data() {
    return {
      styles: [
        { name: this.$t('油画风格'), value: 'oil', hex: '#FF0000' },
        { name: this.$t('黏土风格🔥'), value: 'clay', hex: '#8B0000' },
        { name: this.$t('毛茸茸'), value: 'mufu', hex: '#FFB6C1' },
      ],
      selectedStyle: { name: '', value: '', hex: '' },
    };
  },
  mounted() {
    this.selectedStyle = this.styles[0];
    // 根据query参数选择风格
    let query = this.$route.query;
    if (query.style) {
      this.selectStyle(query.style);
    }
  },
  methods: {
    selectStyle(styleID) {
      let style = this.styles.find(style => style.value === styleID);
      if (!style) {
        style = this.styles[0];
      }
      this.selectedStyle = style;
      this.$router.push({ query: { "style": style.value} });
      // 关闭下拉菜单
      document.activeElement.blur()
      let dropdownContent = document.querySelector('.dropdown-content');
      dropdownContent.animate([
        { opacity: 1 },
        { opacity: 0 }
      ], {
        duration: 200
      });
      setTimeout(() => {
        dropdownContent.style.display = 'none';
        setTimeout(() => {
          dropdownContent.style.display = '';
        }, 100);
      }, 200);
    },
  },
  watch: {
  },
  setup() {
    // 默认图片路径的示例定义
    const defaultImagePaths = ref({
      first: require('@/assets/style/origin.png'), // 默认上传图片路径
      second: require('@/assets/style/result.png'), // 默认处理后图片路径
    });

    return {
      defaultImagePaths
    };
  },
};
</script>

<style scoped>
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px auto 0;
    gap: 20px;
  }

  .hair-colored {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: calc(100vh - 100px);
  }

  .color-circle {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
    border: 2px solid white;
    /* 设置白色描边 */
  }
</style>
