<template>
  <div class="container">
  </div>
  <ImageProcessWithServer class="hair-colored" 
    submitButtonText="提交4"
    functionName="upscale"
    :params="{}"
    :defaultImages="defaultImagePaths">
  </ImageProcessWithServer>
</template>

<script>
import { ref } from 'vue';

import ImageProcessWithServer from '../components/ImageProcessWithServer.vue';

export default {
  components: {
    ImageProcessWithServer,
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
  setup() {
    // 默认图片路径的示例定义
    const defaultImagePaths = ref({
      first: require('@/assets/upscale/origin.png'), // 默认上传图片路径
      second: require('@/assets/upscale/result.png'), // 默认处理后图片路径
    });

    return {
      defaultImagePaths
    };
  },
};
</script>

<style scoped>
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px auto 0;
    gap: 20px;
  }

  .hair-colored {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: calc(100vh - 100px);
  }

  .color-circle {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
    border: 2px solid white;
    /* 设置白色描边 */
  }
</style>
