<template>
  <div class="container">
    <textarea type="text" v-model="text" placeholder="输入内容描述" rows="2" cols="40" class="textarea textarea-bordered" />
  </div>
  <ImageProcessWithServer class="draw" 
    functionName="video"
    submitButtonText="Video提交10"
    isTextToImage=true
    :params="{ desc: text }"
    :defaultImages="defaultImagePaths" />
</template>

<script>
import { ref } from 'vue';

import ImageProcessWithServer from '../components/ImageProcessWithServer.vue';

export default {
  components: {
    ImageProcessWithServer,
  },
  data() {
    return {
      text: '炊烟袅袅，樱花飘飘'
    };
  },
  methods: {
    selectColor(color) {
      this.selectedColor = color;
      // 关闭下拉菜单
      document.activeElement.blur()
      let dropdownContent = document.querySelector('.dropdown-content');
      dropdownContent.animate([
        { opacity: 1 },
        { opacity: 0 }
      ], {
        duration: 200
      });
      setTimeout(() => {
        dropdownContent.style.display = 'none';
        setTimeout(() => {
          dropdownContent.style.display = '';
        }, 100);
      }, 200);
    },
  },
  setup() {
    // 默认图片路径的示例定义
    const defaultImagePaths = ref({
      // first: require('@/assets/aiimg/result.png'), // 默认上传图片路径
    });

    return {
      defaultImagePaths
    };
  },
};
</script>

<style scoped>
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px auto 0;
    gap: 20px;
  }

  .draw {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: calc(100vh - 100px);
  }

  .color-circle {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
    border: 2px solid white;
    /* 设置白色描边 */
  }
</style>
