import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDRDlKfOnAuoYR1rAr1bttBwEFDUnaG7Gg",
    authDomain: "moemiku.firebaseapp.com",
    projectId: "moemiku",
    storageBucket: "moemiku.appspot.com",
    messagingSenderId: "886959392961",
    appId: "1:886959392961:web:43012c208023bbfb3eec40",
    measurementId: "G-VM7LJNJFMY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, 'app_opened');

import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import router from './router'; // 确保路径正确
import i18n from './i18n'

createApp(App)
    .use(router)
    .use(i18n)
    .mount('#app');