<template>
    <dev class="top-right-widget fixed top-2 right-2">
        <div class="top-right-widget" v-if="user.isLogin">
            <div id="dropdownMenu" class="dropdown dropdown-hover dropdown-end">
                <div tabindex="0" role="button" class="btn" id="paymenubtn">
                    <CoinInfo />
                </div>
                <ul tabindex="0" id="paymenu" class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box">
                    <li class="hover:bg-900">
                        <div class="payment-card bg-base-100" v-if="user.isLogin && !loading">
                            <div class="card dark:bg-base-300 border border-gray-300 dark:border-gray-900 dark:text-gray-100" v-for="option in paymentOptions" :key="option.id"
                                @click="triggerPurchase($event, option.id)">
                                <div class="card-header">
                                    <h2 class="text-2xl text-center">{{ option.title }}</h2> <!-- 较大的标题 -->
                                </div>
                                <div class="card-body">
                                    <!-- <p class="text-base text-center text-gray-400">{{ option.description }}</p> 默认的文本大小 -->
                                    <h1 class="text-3xl text-center" v-html="option.price"></h1> <!-- 特别突出的价格 -->
                                    <p class="text-sm text-center text-gray-700 dark:text-gray-300" style="margin-top:0px">包括{{
                                        option.coins }}🥇</p>
                                    <!-- 较小的详细信息 -->
                                    <button class="btn-buy btn btn-accent">立即购买</button>
                                </div>
                            </div>
                        </div>
                        <div v-if="loading" class="text-center">
                            <span class="loading loading-ring loading-lg"></span>
                        </div>
                        <SuperPayment id="buyButton" :productID="productID" v-show="false" />
                    </li>
                    <li>
                        <LoginPage />
                    </li>
                </ul>
            </div>
        </div>
        <LoginPage v-else />
        <!-- <RedeemCode /> -->
    </dev>
    <div v-if="showWechatGuide" class="fixed inset-0 bg-black bg-opacity-75 justify-center items-center z-50"
        @click="showWechatGuide=false">
        <img src="../assets/wechatguide.png" alt="Open in browser instructions" class="max-w-full h-auto">
    </div>
</template>

<script setup>
import { reactive, inject, watch, ref, nextTick } from 'vue';
import CoinInfo from '../components/CoinInfo.vue';
import SuperPayment from '../components/SuperPayment.vue';
import LoginPage from './User/LoginPage.vue';
//   import RedeemCode from '@/components/RedeemCode.vue';
import { getAnalytics, logEvent } from "firebase/analytics";

let user = inject('user');

const analytics = getAnalytics();

// 点击外部关闭菜单
const closeDropdown = event => {
    const dropdownMenu = document.getElementById('dropdownMenu');
    if (!dropdownMenu.contains(event.target)) {
        dropdownMenu.classList.remove('dropdown-open');
    }
};

watch(user, (newUser) => {
    if (newUser.showChargeCoin) {
        user.showChargeCoin = false;
        // 展示充值面板 paymenubtn激活
        var dropdown = document.getElementById('dropdownMenu');
        dropdown.classList.toggle('dropdown-open');
        document.addEventListener('click', closeDropdown);
    }
});

const paymentOptions = reactive([
    { id: "coin_10", title: '限时半价', price: '¥9.9&nbsp<del class="text-gray-400 text-2xl">¥20</del>', coins: '200', description: '支付内测期间限定' },
    { id: "coin_50", title: '标准包', price: '¥50&nbsp<del class="text-gray-400 text-2xl">¥60</del>', coins: '600', description: '50元优惠充值' },
    { id: "coin_100", title: '超值大礼包', price: '¥100&nbsp<del class="text-gray-400 text-2xl">¥160</del>', coins: '1600', description: '100元优惠充值' },
]);

const productID = ref('');
const loading = ref(false);
const showWechatGuide = ref(false);

const triggerPurchase = (event, optionId) => {
    console.log('购买选项', optionId);
    // 阻止事件冒泡
    event.stopPropagation();

    logEvent(analytics, 'purchase_option_clicked', { optionId });

    // 获取当前网页UA判断是否在微信中，如果在微信中，显示全屏引导，提示用系统浏览器打开
    const ua = navigator.userAgent.toLowerCase();
    const isWeixin = ua.indexOf("micromessenger") != -1
    if (isWeixin) {
        showWechatGuide.value = true;
        logEvent(analytics, 'wechat_guide_shown');
        return
    }
    let buyButton = document.getElementById('buyButton');
    productID.value = optionId;
    nextTick(() => {
        buyButton.click();
    });
    // 显示loading
    loading.value = true;
    // buyButton.setAttribute('productID', optionId);
    // // id选择
    // buyButton.click();
}
</script>

<style scoped>
.top-right-widget {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
}

#paymenu {
    max-width: 100vw;
}

.payment-card {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    gap: 10px;
    white-space: nowrap;
    max-width: 90vw;
}

@media (max-width: 544px) {

    /* 对应 Tailwind CSS 的 md 断点 */
    #paymenu {
        transform: scale(0.6);
        transform-origin: 25% 0%;
    }
    .payment-card {
        max-width: 150vw;
    }
}

.card {
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    /* padding: 20px; */
    padding-top: 20px;
}

.card-header {
    font-size: 18px;
    font-weight: bold;
}

.card-body p {
    font-size: 14px;
    margin: 5px 0;
}

.card-body {
    padding-top: 20px;
}

.btn-buy {
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

</style>