<template>
    <div class="hero min-h-full rounded-l-xl bg-base-200">
      <div class="hero-content py-12">
        <div class="max-w-md">
  
          <h1 class='text-3xl text-center font-bold '>
            <img src="../../assets/logo.webp" class="w-12 inline-block mr-2 mask mask-circle" alt="logo" />影像魔术师
          </h1>
  
          <div class="text-center mt-12">
            <img src="../../assets/Intro.webp" alt="Awesome" class="w-48 inline-block rounded-md" />
          </div>
          
          <h1 className="text-2xl mt-8 font-bold">影像魔术师：AI图片编辑工具</h1>
          <p className="py-2 mt-4">✓ <span className="font-semibold">一键大胸</span>功能，轻松调整人物体型</p>
          <p className="py-2">✓ <span className="font-semibold">一键染发</span>，支持各种流行发色更换</p>
          <p className="py-2">✓ <span className="font-semibold">一键变高清</span>，提升图片质量，让照片更加清晰</p>
          <p className="py-2">✓ 用户友好的<span className="font-semibold">操作界面</span>，简单易用</p>
          <p className="py-2 mb-4">✓ 集成<span className="font-semibold">AI智能算法</span>，确保编辑效果自然真实</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>

  export default {
    components: {
    }
  }
  </script>
  