<template>
  <div :class="`form-control w-full ${containerStyle}`">
    <label class="label">
      <span :class="`label-text text-base-content ${labelStyle}`">{{ labelTitle }}</span>
    </label>
    <!-- 使用:value绑定modelValue，并监听input事件 -->
    <input :type="type" :value="modelValue" @input="onInput" :placeholder="placeholder" class="input input-bordered w-full" />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    labelTitle: {
      type: String,
      default: '',
    },
    labelStyle: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    containerStyle: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  methods: {
    onInput(event) {
      // 发射update:modelValue事件，携带新的输入值
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>
