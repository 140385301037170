<template>
  <div class="navbar bg-base-100">
    <button type="button" class="md:hidden relative rounded-md p-2 text-gray-400 lg:hidden"
      @click="isSliderBarOpen = true">
      <span class="absolute -inset-0.5"></span>
      <span class="sr-only">Open menu</span>
      <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path>
      </svg>
    </button>
    <img alt="影像魔术师" src="./assets/logo.webp" width="35" height="35"
      style="margin-left: 8px; margin-right: 10px; border-radius: 10px">
    <h1 class="font-title text-xl">影像魔术师</h1>
  </div>
  <div class="flex flex-row-reverse">
    <!-- Body -->
    <div class="flex-grow flex-shrink" style="flex-grow: 1; flex-shrink: 1;">
      <div v-if="showAll" role="tablist" class="tabs tabs-boxed">
        <!-- 动态显示当前组的标签 -->
        <a v-for="item in currentItems" :key="item.id" role="tab" class="tab"
          :class="{ 'tab-active': currentTab === item.tab }" @click="setCurrentTab(item.tab, currentGroup)">
          {{ item.name }}
        </a>
      </div>
      <router-view></router-view>
    </div>
    <!-- Sidebar -->
    <div class="w-64 p-4 shadow-lg hidden md:block" style="flex-grow: 0; flex-shrink: 0;">
      <h1 class="text-xl font-medium dark:text-gray-100 mt-4">探索AI艺术</h1>
      <ul class="mt-4">
        <li v-for="(group, key) in groups" :key="key">
          <a :class="['text-lg block p-2 hover:bg-gray-100 hover:dark:bg-gray-600 dark:text-gray-100 cursor-pointer', { 'bg-gray-200 dark:bg-gray-700': currentGroup === key }]"
            @click="setCurrentGroup(key)">
            {{ group.name }}
          </a>
        </li>
      </ul>
    </div>
    <div class="relative">
      <!-- 蒙层 -->
      <div v-if="isSliderBarOpen" class="fixed inset-0 bg-black bg-opacity-50 z-40" @click="isSliderBarOpen = false">
      </div>
      <!-- 侧边栏 -->
      <div :class="isSliderBarOpen ? 'translate-x-0' : '-translate-x-full'"
        class="fixed top-0 left-0 h-full w-64 bg-base-100 transition-transform z-50">
        <!-- Sidebar -->
        <div class="w-64 p-4 shadow-lg bg-base-100" style="flex-grow: 0; flex-shrink: 0;">
          <h1 class="text-xl font-medium dark:text-gray-100 mt-16">探索AI艺术</h1>
          <ul class="mt-4">
            <li v-for="(group, key) in groups" :key="key">
              <a :class="['text-lg block p-2 hover:bg-gray-100 hover:dark:bg-gray-600 dark:text-gray-100 cursor-pointer', { 'bg-gray-200 dark:bg-gray-700': currentGroup === key }]"
                @click="setCurrentGroup(key)">
                {{ group.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer p-10 bg-neutral text-neutral-content">
    <aside>
      <img alt="影像魔术师" src="./assets/logo.webp" width="50" height="5-" style="border-radius: 10px" class="fill-current">
      <!-- <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd"
        clip-rule="evenodd" class="fill-current">
        <path
          d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z">
        </path>
      </svg> -->
      <p>Moemiku Ltd.<br>影像魔术师：一键大胸、AI生图等AI图像处理应用，技术革新，创造奇迹。</p>
    </aside>
    <nav>
      <h6 class="footer-title">联系我们</h6>
      <div class="grid grid-flow-col gap-4">
        <a href="https://twitter.com/moeimiku"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
            viewBox="0 0 24 24" class="fill-current">
            <path
              d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z">
            </path>
          </svg></a>
        <div v-if="showAll" class="dropdown dropdown-hover dropdown-top"
          style="margin-left: auto; margin-right: 20px; align-self: flex-end;">
          <div tabindex="0"><svg id="weChat" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
              xmlns="http://www.w3.org/2000/svg" height="99.06mm" width="103.9mm" version="1.1"
              xmlns:cc="http://creativecommons.org/ns#" xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 368.10109 351.00342" xmlns:dc="http://purl.org/dc/elements/1.1/">
              <defs>
                <filter id="d" style="color-interpolation-filters:sRGB" height="1.073" width="1.071" y="-.03639"
                  x="-.03562">
                  <feGaussianBlur stdDeviation="4.545524" />
                </filter>
                <radialGradient id="a" gradientUnits="userSpaceOnUse" cy="-44.21" cx="3538"
                  gradientTransform="matrix(1.014 -.2270 .2141 .9563 -36.91 766.7)" r="153.2">
                  <stop stop-color="#a3dc27" offset="0" />
                  <stop stop-color="#62c627" offset="1" />
                </radialGradient>
                <radialGradient id="b" gradientUnits="userSpaceOnUse" cy="-60.47" cx="4113"
                  gradientTransform="matrix(1 0 0 .8866 5.176 1.581)" r="117.6">
                  <stop stop-color="#a6a5a4" offset="0" />
                  <stop stop-color="#b3b3b3" offset="1" />
                </radialGradient>
                <filter id="e" style="color-interpolation-filters:sRGB" height="1.1" width="1.088" y="-.04979"
                  x="-.04415">
                  <feGaussianBlur stdDeviation="4.3256066" />
                </filter>
                <linearGradient id="c" y2="403.7" gradientUnits="userSpaceOnUse" x2="3642" y1="-48.37" x1="3635">
                  <stop stop-color="#fff" offset="0" />
                  <stop stop-color="#fff" stop-opacity="0" offset="1" />
                </linearGradient>
              </defs>
              <g transform="translate(649.8 -39.72)">
                <g transform="translate(-4038 242.7)">
                  <path
                    d="m3704 107.7c-0.1926-0.3117 0.058-0.8653 0.5571-1.23 2.74-0.9721 0.2238 1.352-0.5571 1.23zm-259-42.95c-1.811-2.765 0.3306-10.92 9.262-35.27 0.9528-2.598 1.588-4.946 1.411-5.217-0.1765-0.2714-2.29-1.888-4.697-3.593-28.47-20.16-46.57-47.72-51.25-78.01-1.147-7.429-1.19-25.57-0.076-32.54 2.93-18.34 10.25-34.91 21.93-49.62 16.77-21.12 44.56-38.61 74.36-46.81 15.93-4.382 27.27-5.832 45.27-5.786 50.78 0.1286 92.84 14.72 119.6 41.49 8.652 8.654 14.75 17.22 20.17 28.33 5.444 11.16 8.925 23.27 10.28 35.78 0.9284 8.557 0.4514 29.65-0.6965 30.8-0.1309 0.131-3.066-0.8776-6.524-2.241-17.63-6.952-31.91-9.649-50.82-9.589-48.09 0.1506-86.67 16.87-106.4 46.11-10.85 16.11-15.94 36.4-14.28 56.99 0.3316 4.123 0.7339 8.106 0.894 8.85 0.2355 1.095 0.1157 1.355-0.6273 1.365-0.5052 0.007-6.056-1.259-12.34-2.813l-11.42-2.825-5.313 3.618c-26.12 17.79-31.92 21.33-35.93 21.97-1.798 0.2876-2.094 0.1861-2.858-0.9791z"
                    filter="url(#d)" fill="#808080" />
                  <path
                    d="m3704 100.9c-0.1926-0.3117 0.058-0.8653 0.5571-1.23 1.409-1.03 1.806-0.7856 0.92 0.5667-0.9196 1.404-0.9979 1.439-1.477 0.6635zm-258-42.93c-1.811-2.765 0.3307-10.92 9.262-35.27 0.9528-2.598 1.588-4.946 1.411-5.217-0.1765-0.2714-2.29-1.888-4.697-3.593-28.47-20.16-46.57-47.72-51.25-78.01-1.147-7.429-1.19-25.57-0.076-32.54 2.93-18.34 10.25-34.91 21.93-49.62 16.77-21.12 44.56-38.61 74.36-46.81 15.93-4.382 27.27-5.832 45.27-5.786 50.78 0.1286 92.84 14.72 119.6 41.49 8.652 8.654 14.75 17.22 20.17 28.33 5.444 11.16 8.925 23.27 10.28 35.78 0.9283 8.557 0.4514 29.65-0.6965 30.8-0.131 0.131-3.066-0.8776-6.524-2.241-17.63-6.952-31.91-9.649-50.82-9.589-48.09 0.1506-86.67 16.87-106.4 46.11-10.85 16.11-15.94 36.4-14.28 56.99 0.3316 4.123 0.7339 8.106 0.894 8.85 0.2355 1.095 0.1157 1.355-0.6273 1.365-0.5052 0.007-6.056-1.259-12.34-2.813l-11.42-2.825-5.313 3.618c-26.12 17.79-31.92 21.33-35.94 21.97-1.798 0.2876-2.094 0.1861-2.858-0.9791z"
                    fill="url(#a)" />
                  <circle stroke-opacity="0" cy="-113.6" cx="3498" r="20.44" fill="#363b2a" />
                  <circle stroke-opacity="0" cy="-109.3" cx="3600" r="20.44" fill="#363b2a" />
                  <path
                    d="m4117-156.3c72.84 1.583 119.5 45.13 118.8 98.17-0.7917 53.04-45.17 75.44-45.17 75.44s7.338 19.53 8.854 30.08c0.1492 1.038 1.001 3.837 0.227 4.544-0.6626 0.6051-1.826-0.0229-2.673-0.3177-12.59-4.378-33.23-21.22-33.23-21.22-68.17 26.94-164.7-17.88-163.1-86.55 2.068-63.4 42.55-95.08 116.4-100.1z"
                    fill-rule="evenodd" transform="matrix(.9821 0 0 .9821 -413.5 86.52)" filter="url(#e)"
                    stroke="url(#b)" stroke-miterlimit="25.2" fill="#808080" />
                  <path
                    d="m3627-77.22c72.84 1.583 119.5 45.13 118.8 98.17-0.7917 53.04-45.17 75.44-45.17 75.44s7.338 19.53 8.854 30.08c0.1492 1.038 1.001 3.837 0.227 4.544-0.6626 0.6051-1.826-0.0229-2.673-0.3177-12.59-4.378-33.23-21.22-33.23-21.22-68.17 26.94-164.7-17.88-163.1-86.55 2.068-63.4 42.55-95.08 116.4-100.1z"
                    fill-rule="evenodd" fill="url(#c)" />
                  <circle cy="-4.632" cx="3593" r="15.95" fill="#363b2a" />
                  <circle cy="-6.032" cx="3675" r="15.95" fill="#363b2a" />
                </g>
              </g>
            </svg></div>
          <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
            <li><img src="./assets/qq.jpg" /></li>
          </ul>
        </div>
      </div>
    </nav>
  </footer>
  <RightTopInfo />
</template>
<script>

import { reactive, provide } from 'vue';
import { useI18n } from 'vue-i18n';
import RightTopInfo from './views/RightTopInfo.vue';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDRDlKfOnAuoYR1rAr1bttBwEFDUnaG7Gg",
  authDomain: "moemiku.firebaseapp.com",
  projectId: "moemiku",
  storageBucket: "moemiku.appspot.com",
  messagingSenderId: "886959392961",
  appId: "1:886959392961:web:43012c208023bbfb3eec40",
  measurementId: "G-VM7LJNJFMY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, 'app_opened');

export default {
  name: 'App',
  components: {
    RightTopInfo,
  },
  setup() {
    const { t } = useI18n();
    if (process.env.VUE_APP_LANG === "en") {
      document.title = t("appTitle");
      document.querySelector("meta[name='description']").setAttribute("content", t("description"));
    }
    const redeem = reactive({
      showRedeemAlert: false
    });
    provide('redeem', redeem);

    const isLogin = localStorage.getItem('token') ? true : false;
    const showLogin = false;
    const showChargeCoin = false;
    const changCount = 0;

    const user = reactive({
      isLogin,
      showLogin,
      showChargeCoin,
      changCount
    });
    provide('user', user);
  },
  data() {
    return {
      isSliderBarOpen: false,
      showAll: process.env.VUE_APP_DISABLE_OTHER !== 'true',
      currentGroup: 'improvement',  // 默认显示创造组
      groups: {
        improvement: {
          name: "改进",
          items: [
            { id: 4, name: '一键大胸', tab: 'BigBoob' },
            { id: 5, name: '一键染发', tab: 'HairColored' },
            // { id: 6, name: '超强去除人物', tab: 'Inpaint' },
            { id: 8, name: '换画风', tab: 'ImgStyleTransfor'},
            { id: 9, name: '一键变高清', tab: 'ImgEnhancer'}
          ],
          defaultTab: 'BigBoob'
        },
        creation: {
          name: "创造",
          items: [
            { id: 2, name: 'StableDiffusion 3生成', tab: 'GenSD3Image' },
            { id: 3, name: '美图创作', tab: 'GenImage' },
            // { id: 7, name: '壁纸生成', tab: 'GenScreenBackground'}
            // 其他创造相关标签
          ],
          defaultTab: 'GenImage'
        },
        video: {
          name: "视频",
          items: [
            { id: 1, name: '视频生成(调试中)', tab: 'VideoGen' },
            // 其他视频相关标签
          ],
          defaultTab: 'VideoGen'
        }
      }
    }
  },
  computed: {
    currentTab() {
      return this.$route.name;
    },
    currentItems() {
      // 根据当前选中的组来动态返回标签数组
      return this.groups[this.currentGroup].items;
    }
  },
  methods: {
    setCurrentTab(tab) {
      // 检查当前tab所属的组
      for (const [key, value] of Object.entries(this.groups)) {
        if (value.items.some(item => item.tab === tab)) {
          this.currentGroup = key;
          break;
        }
      }
      this.$router.push({ name: tab });
    },
    setCurrentGroup(group) {
      this.isSliderBarOpen = false;
      this.currentGroup = group;
      // 切换组时设置为该组的默认tab
      const tab = this.groups[group].defaultTab;
      this.$router.push({ name: tab });
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  margin-top: 0px;
  height: 100vh;
}

.button-container {
  display: flex;
  position: absolute;
  right: 160px;
  top: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
}

#weChat {
  width: 28px;
  height: 28px;
}
</style>
